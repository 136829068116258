<template>
  <div class="">
    <div class="p-0 m-0">
      <b-container>
        <b-card
          no-body
          class="mr-1"
        >
          <div class="pl-1 pt-1 ">
            <span class="saj-title">
              {{ $t("Search Filter") }}
            </span>
          </div>
          <b-card-body>
            <b-row class="px-1 pb-1">
              <b-col
                cols="12"
                md="4"
                class="mb-md-0"
              >
                <label class="saj-text">{{ $t("Qualification") }}:</label>
                <b-form-select
                  v-model="selectedDistrict"
                  :options="districtList"
                  :value="districtList"
                  size="lg"
                  class="form"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0"
              >
                <label class="saj-text">{{ $t("Institute") }}:</label>
                <b-form-select
                  v-model="selectedCategory"
                  :options="categoryList"
                  :value="categoryList"
                  size="lg"
                  class="saj-text"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0"
              >
                <label class="saj-text">{{ $t("field") }}:</label>
                <b-form-select
                  v-model="selectedEmployeeID"
                  :options="empIDList"
                  :value="empIDList"
                  size="lg"
                  class="saj-text"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2 mr-1 pt-1"
              >
                <label class="saj-text">{{ $t("Search Name") }}:</label>
                <b-form-input
                  id="icons-search"
                  v-model="searchname"
                  placeholder="Search name..."
                  size="lg"
                />
              </b-col>

              <b-row
                cols="12"
                class="flex-row flex-column mr-1"
              >
                <b-col cols="12">
                  <b-button
                    md="4"
                    variant="primary"
                    class="saj-title d-flex justify-content-center mt-3"
                    block
                    style="color:black !important;
                    "
                  >
                    <span class="d-md-none d-lg-block"> {{ $t("Search") }} </span>
                  </b-button>
                </b-col>

              </b-row>

              <b-row>
                <b-col>
                  <b-button
                    md="4"
                    variant="primary"
                    class="saj-title d-flex justify-content-center mt-3"
                    block
                    style="color:black !important;
                    "
                  >
                    <span class="d-md-none d-lg-block"> {{ $t("Clear") }} </span>
                  </b-button>
                </b-col>
              </b-row>

            </b-row>
          </b-card-body>
        </b-card>
        <b-card style="overflow-y: auto;">
          <b-table
            class="styleTable"
            style="font-size: 20px;"
            :items="settingList"
            :fields="fields"
            label-size="lg"
            bordered
          >
            <template #head()="data">
              <span
                class="saj-text d-flex justify-content-center"
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(qualification)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.qualification }}
              </div>
            </template>
            <template #cell()="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.qualification }}
              </div>
            </template>
            <template #cell(institute)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.institute }}
              </div>
            </template>
            <template #cell(field)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.field }}
              </div>
            </template>
            <template #cell(year)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.year }}
              </div>
            </template>
            <template #cell(attachment)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.item.attachment }}
              </div>
            </template>
            <template #cell(action)="">
              <div
                class="saj-text d-flex justify-content-center"
                style="
            font-family: Poppins;
            font-weight: 400;
            font-style: normal;
          "
              >
                <!-- <div v-if="existitem.status === 'pending'"> -->
                <b-button
                  pill
                  variant="warning"
                >
                  {{ $t('Approve') }}</b-button>
                <!-- </div> -->
                <!-- <div v-else> -->
                <b-button
                  pill
                  variant="success"
                >
                  {{ $t('Approved') }}</b-button>
                <!-- </div> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-container>
    </div>
  </div>
</template>
<script>
import {
  BTable,
  BCard,
  // BCardBody,
  BContainer,
  BCol,
  BRow,
  BFormInput,
  BFormSelect,
  BButton,
  // BCardHeader,

} from "bootstrap-vue"

export default {
  components: {
    BCard,
    // BCardBody,
    BContainer,
    BCol,
    BRow,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    // BCardHeader,

  },

  data(){
    return {
      searchname: '',
      selectedEmployeeID: '',
      selectedCategory: '',
      selectedDistrict: '',
      districtList: [],
      categoryList: [],
      allEmpID: [],
      empIDList: [],

      settingList: [
        {
          qualification: '001',
          institute: 'test1',
          field: '1',
          year: '1',
          attachment: '1',
        },
      ],
      fields: [
        {
          key: 'qualification', label: 'Qualification', thClass: 'text-center',
        },
        {
          key: 'institute', label: 'Institute', tdClass: 'text-center',
        },
        {
          key: 'field', label: 'Field', tdClass: 'text-center',
        },
        {
          key: 'year', label: 'Year', tdClass: 'text-center',
        },
        {
          key: 'attachment', label: 'Attachment', tdClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', tdClass: 'text-center',
        },
      ],
    }
  },
  mounted(){
  },
  methods: {
  },
}
</script>
<style>

</style>
